<template>
  <div class="all_main_wrap">
    <div class="flex-between">
      <div class="menu-left">
        <a-steps
          direction="vertical"
          :current="current"
          @change="onMenuClick"
        >
          <a-step v-for="(item,index) in menuSteps" :key="index">
            <a-icon slot="icon" :style="{color: (current == index  && item.type == 0) ? '#3681f0' : getColorType(item.type)}" :type="getIconType(item.type)"></a-icon>
            <span :style="{color:(current == index ? '#3681f0':'')}" slot="title">{{item.name}}</span>
          </a-step>
        </a-steps> 
      </div>
      <!-- 课程表单填写区域 -->
      <div class="content-right">
        <div class="status">
          <a-button
            v-hasPermi="['class:publist:updatestatus']"
            @click="updateStatus"
            :loading="loadingBtn"
            type="primary"
            >{{status == 1 || status == 3 ? '上架' : '下架' }}</a-button
          >
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loadingBtn:false,
      current: 0,
      menuSteps: [
        {
          name: '基础配置',
          fileid: 'basicInfo',
          type: 0,
        },
        {
          name: '展示详情',
          fileid: 'showInfo',
          type: 0,
        },
        {
          name: '目录管理',
          fileid: 'sectionInfo',
          type: 0,
        },
        {
          name: '观看限制',
          fileid: 'watchConfig',
          type: 0,
        },
        {
          name: '购买设置',
          fileid: 'buyConfig',
          type: 0,
        },
        {
          name: '学习辅助',
          fileid: 'studyAssist',
          type: 0,
        },
      ],
      courseId:0, // 课程id
      status: null
    };
  },
  // 事件处理器
  methods: {
    // 页面跳转
    onMenuClick(e) {
      if(!this.courseId){
        this.$message.error('请先填写基础配置项！')
        return
      }
      this.current = e;
      let url = ''
      switch (e) {
        case 0:
          url = '/admin/curriculum/GeneralView/BasicInformation'
          break;
        case 1:
          url = '/admin/curriculum/GeneralView/DisplayDetails'
          break;
        case 2:
          url = '/admin/curriculum/GeneralView/Directory'
          break;
        case 3:
          url = '/admin/curriculum/GeneralView/WatchSet'
          break;
        case 4:
          url = '/admin/curriculum/GeneralView/PurchaseSet'
          break;
        case 5:
          url = '/admin/curriculum/GeneralView/Study'
          break;
      }
      this.$router.replace({
        path: url,
        query: {
          id: (this.courseId ? this.courseId : undefined),
          status: (this.status ? this.status : undefined)
        },
      });
    },

    getIconType (type) {
      let icon = null
      switch (type) {
        case 0:
          icon = 'question-circle'
          break
        case 1:
          icon = 'exclamation-circle'
          break
        case 2:
          icon = 'check-circle'
          break
        default:
          icon = null
      }
      return icon
    },

    getColorType (type) {
      let color = null
      switch (type) {
        case 0:
          color = '#797979'
          break
        case 1:
          color = '#ff2e00'
          break
        case 2:
          color = '#22c134'
          break
        default:
          color = null
      }
      return color
    },

    getRouteCurrent (url) {
      let current = 0
      switch (url) {
        case '/admin/curriculum/GeneralView/BasicInformation':
          current = 0
          break;
        case '/admin/curriculum/GeneralView/DisplayDetails':
          current = 1
          break;
        case '/admin/curriculum/GeneralView/Directory':
          current = 2
          break;
        case '/admin/curriculum/GeneralView/WatchSet':
          current = 3
          break;
        case '/admin/curriculum/GeneralView/PurchaseSet':
          current = 4
          break;
        case '/admin/curriculum/GeneralView/Study':
          current = 5
          break;
      }
      this.current = current
    },

    // 更新菜单修改状态
    onChildInit(fileid, type){
      this.menuSteps.forEach(element => {
        if(element.fileid == fileid){
          element.type = type
        }
      });
    },

    // 接收基础配置创建的课程id
    onChildCourseId(courseId){
      this.courseId = courseId
      this.$router.replace({
        path: '/admin/curriculum/GeneralView/BasicInformation',
        query: {
          id: courseId,
          status: (this.status ? this.status : undefined)
        },
      });
    },

    // 获取菜单列表
    getMenuList(){
      this.$ajax({
        url: "/hxclass-management/course/getCourseModule",
        params: {
          courseId: this.courseId
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          const basicInfo = res.data.basicInfo  // 基础配置
          const showInfo = res.data.showInfo  // 展示详情
          const sectionInfo = res.data.sectionInfo  // 目录管理
          const watchConfig = res.data.watchConfig  // 观看限制
          const buyConfig = res.data.buyConfig  // 购买设置
          const studyAssist = res.data.studyAssist // 学习辅助
          this.menuSteps.forEach(element => {
            if(element.fileid == 'basicInfo'){
              element.type = basicInfo
            } else if (element.fileid == 'showInfo') {
              element.type = showInfo
            } else if (element.fileid == 'sectionInfo') {
              element.type = sectionInfo
            } else if (element.fileid == 'watchConfig') {
              element.type = watchConfig
            } else if (element.fileid == 'buyConfig') {
              element.type = buyConfig
            } else if (element.fileid == 'studyAssist') {
              element.type = studyAssist
            }
          });
        }
      });
    },

    // 课程上下架
    updateStatus(){
      let _this = this;
      const status = _this.status == 1 || _this.status == 3 ? 1 : 0
      let isPass = true // 模块是否填写完整
      let moduleName = ''  // 模块名称
      if(status == 1){
        _this.menuSteps.forEach(element => {
          if(element.type != 2){
            isPass = false
            moduleName = element.name
          }
        });
      }
      if(!isPass){
        _this.$message.warning(`请将【${moduleName}】模块配置完整`)
        return
      }
      
      this.$confirm({
        title: `确定${status ? '上架' : '下架'}课程吗？`,
        okText: "确认",
        onOk() {
          _this.loadingBtn = true;
          let url = (status ? '/hxclass-management/course/courseGround' : '/hxclass-management/course/courseNotGround')
          _this
            .$ajax({
              url: url,
              method: "put",
              params: {
                courseId: _this.courseId,
                status: (status) ? 2 : 3
              }
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success(`${status ? '上架' : '下架'}成功`)
                _this.$router.go(-1)
              } else {
                _this.$message.error(res.message);
              }
              _this.loadingBtn = false;
            })
            .catch((err) => {
              
            });
        },
        onCancel() {},
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getRouteCurrent(this.$route.path)
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // 获取课程id
    this.courseId = this.$route.query.id ? this.$route.query.id : 0
    this.status = this.$route.query.status ? this.$route.query.status : null

    if(this.courseId){
      this.getMenuList()
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.ant-steps-vertical .ant-steps-item{
  height: 80px;
}
.flex-between {
  display: flex;
  .menu-left{
    margin-right: 30px;
  }
  .content-right{
    flex: 1;
    position: relative;
    .status{
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
